import React from "react";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';

import ControllerPage404 from "../pages/Controller/Page404";

import AuthLogin from "../pages/Auth/Login";

import WebMain from "../pages/Web/Main";
import WebHome from "../pages/Web/Home";
import WebAbout from "../pages/Web/About";
import WebPlans from "../pages/Web/Plans";
import WebFAQ from "../pages/Web/FAQ";
import WebContact from "../pages/Web/Contact";
import WebPolicy from "../pages/Web/Policy";

import AdminDashboard from "../pages/Admin/Dashboard";
import AdminMeuPerfil from "../pages/Admin/MeuPerfil";

import AdminPlanoList from "../pages/Admin/Plano/List";
import AdminPlanoForm from "../pages/Admin/Plano/Form";
import AdminPlanoConfig from "../pages/Admin/Plano/Config";
import AdminPlanoConfigList from "../pages/Admin/Plano/Config/List";
import AdminPlanoConfigForm from "../pages/Admin/Plano/Config/Form";

import AdminEspecialidadeList from "../pages/Admin/Especialidade/List";
import AdminEspecialidadeForm from "../pages/Admin/Especialidade/Form";

import AdminPerguntaList from "../pages/Admin/Pergunta/List";
import AdminPerguntaForm from "../pages/Admin/Pergunta/Form";

import AdminProcessoTribunalList from "../pages/Admin/ProcessoTribunal/List";
import AdminProcessoTribunalForm from "../pages/Admin/ProcessoTribunal/Form";

import AdminProcessoTipoAcaoList from "../pages/Admin/ProcessoTipoAcao/List";
import AdminProcessoTipoAcaoForm from "../pages/Admin/ProcessoTipoAcao/Form";

import AdminProcessoStatusList from "../pages/Admin/ProcessoStatus/List";
import AdminProcessoStatusForm from "../pages/Admin/ProcessoStatus/Form";

import AdminProcessoSituacaoList from "../pages/Admin/ProcessoSituacao/List";
import AdminProcessoSituacaoForm from "../pages/Admin/ProcessoSituacao/Form";

import AdminProcessoInstanciaList from "../pages/Admin/ProcessoInstancia/List";
import AdminProcessoInstanciaForm from "../pages/Admin/ProcessoInstancia/Form";

import AdminProcessoChanceGanharList from "../pages/Admin/ProcessoChanceGanhar/List";
import AdminProcessoChanceGanharForm from "../pages/Admin/ProcessoChanceGanhar/Form";

import AdminUsuarioList from "../pages/Admin/Usuario/List";
import AdminUsuarioForm from "../pages/Admin/Usuario/Form";

import AdminEscritorioList from "../pages/Admin/Escritorio/List";
import AdminEscritorioForm from "../pages/Admin/Escritorio/Form";
import AdminEscritorioPlano from "../pages/Admin/Escritorio/Plano";
import AdminEscritorioEquipeList from "../pages/Admin/Escritorio/Equipe/List";
import AdminEscritorioEquipeForm from "../pages/Admin/Escritorio/Equipe/Form";

import AdminTagList from "../pages/Admin/Tags/List";
import AdminTagForm from "../pages/Admin/Tags/Form";

import AdminGrupoPermissaoList from "../pages/Admin/GrupoPermissao/List";
import AdminGrupoPermissaoForm from "../pages/Admin/GrupoPermissao/Form";

import AdminPermissaoList from "../pages/Admin/Permissao/List";
import AdminPermissaoForm from "../pages/Admin/Permissao/Form";

import AdminTipoDeUsuarioList from "../pages/Admin/TipoDeUsuario/List";
import AdminTipoDeUsuarioForm from "../pages/Admin/TipoDeUsuario/Form";

import AdminEquipeList from "../pages/Admin/Equipe/List";
import AdminEquipeForm from "../pages/Admin/Equipe/Form";

import AdminClienteList from "../pages/Admin/Cliente/List";
import AdminClienteForm from "../pages/Admin/Cliente/Form";

import AdminAdvogadoList from "../pages/Admin/Advogado/List";
import AdminAdvogadoForm from "../pages/Admin/Advogado/Form";
import WebPerfil from "../pages/Web/Perfil";
import WebPerfilSobre from "../pages/Web/PerfilSobre";
import WebPerfilEquipe from "../pages/Web/PerfilEquipe";
import WebPerfilCliente from "../pages/Web/PerfilCliente";
import WebPerfilContato from "../pages/Web/PerfilContato";
import WebPerfilGaleria from "../pages/Web/PerfilGaleria";
import AdminProcessList from "../pages/Admin/Process/List";
import AdminProcessForm from "../pages/Admin/Process/Form";
import AdminProcessCommentList from "../pages/Admin/ProcessComment/List";
import AdminProcessCommentForm from "../pages/Admin/ProcessComment/Form";
import AdminProcessDocumentList from "../pages/Admin/ProcessDocument/List";
import AdminProcessDocumentForm from "../pages/Admin/ProcessDocument/Form";
import AdminParteContrariaList from "../pages/Admin/ParteContraria/List";
import AdminParteContrariaForm from "../pages/Admin/ParteContraria/Form";
import AdminMetodoPagamentoList from "../pages/Admin/MetodoPagamento/List";
import AdminMetodoPagamentoForm from "../pages/Admin/MetodoPagamento/Form";
import AdminMinhaConta from "../pages/Admin/MinhaConta";
import AdminAlterarPlano from "../pages/Admin/AlterarPlano";
import AdminAlterarPlanoCart from "../pages/Admin/AlterarPlanoCart";
import AdminTarefaList from "../pages/Admin/Tarefas/List";
import AdminTaskForm from "../pages/Admin/Tarefas/Form";
import AdminTaskCommentList from "../pages/Admin/TaskComment/List";
import AdminTaskCommentForm from "../pages/Admin/TaskComment/Form";
import AdminTaskDocumentForm from "../pages/Admin/TaskDocument/Form";
import AdminTaskDocummentList from "../pages/Admin/TaskDocument/List";
import AdminClientCommentList from "../pages/Admin/ClientComment/List";
import AdminClientCommentForm from "../pages/Admin/ClientComment/Form";
import AdminClientDocumentList from "../pages/Admin/ClientDocument/List";
import AdminClientDocumentForm from "../pages/Admin/ClientDocument/Form";
import AdminTreinamentoList from "../pages/Admin/Treinamento/List";
import AdminTreinamentoForm from "../pages/Admin/Treinamento/Form";
import AdminProcessFinancialList from "../pages/Admin/ProcessFinancial/List";
import AdminProcessFinancialForm from "../pages/Admin/ProcessFinancial/Form";
import AdminPageList from "../pages/Admin/Page/List";
import AdminPageForm from "../pages/Admin/Page/Form";
import AdminPagePage from "../pages/Admin/Page/Page";
import AdminPageView from "../pages/Admin/Page/View";
import WebTerm from "../pages/Web/Term";
import AdminPageAceites from "../pages/Admin/Page/Aceites";
import AdminProcessPrestacaoContas from "../pages/Admin/Process/PrestacaoContas";
import AdminEscritorioTipoUsuarioList from "../pages/Admin/Escritorio/TipoUsuario/List";
import AdminEscritorioTipoUsuarioForm from "../pages/Admin/Escritorio/TipoUsuario/Form";
import Dash from "../pages/Admin/Dashs";
import AdminMyProcessList from "../pages/Admin/MyProcess/List";
import AdminBanners from "../pages/Admin/Banners/List";
import AdminMyProcessCommentList from "../pages/Admin/MyProcessComment/List";
import AdminMyProcessPrestacaoContas from "../pages/Admin/MyProcess/PrestacaoContas";
import AdminTreinamentoEmbed from "../pages/Admin/Treinamento/Embed";
import WebRegister from "../pages/Web/Cadastrese";
import AuthForgot from "../pages/Auth/Forgot";

class AppStack extends React.Component {

    render = () => {

        return (
            <BrowserRouter>
                <Routes>
                    <Route path="" element={<WebMain />} >
                        <Route path="" element={<WebHome />} />
                        <Route path="quemsomos" element={<WebAbout />} />
                        <Route path="planos" element={<WebPlans />} />
                        <Route path="faq" element={<WebFAQ />} />
                        <Route path="contato" element={<WebContact />} />
                        <Route path="politica" element={<WebPolicy />} />
                        <Route path="termos" element={<WebTerm />} />
                        <Route path="cadastrese" element={<WebRegister />} />
                        <Route path="*" element={<ControllerPage404 />} />
                    </Route>
                    <Route path="perfil/:id" element={<WebPerfil />} >
                        <Route path="" element={<Navigate to="sobre"/>}/>
                        <Route path="sobre" element={<WebPerfilSobre />}/>
                        <Route path="nossaequipe" element={<WebPerfilEquipe />}/>
                        <Route path="contatos" element={<WebPerfilContato />}/>
                        <Route path="clientes" element={<WebPerfilCliente />}/>
                        <Route path="galeria" element={<WebPerfilGaleria />}/>
                    </Route>
                    <Route path="login" element={<AuthLogin />} />
                    <Route path="esqueceuasenha" element={<AuthForgot />} />
                    <Route path="painel" element={<AdminDashboard />}>
                        <Route path="" element={<Dash />}/>
                        <Route path="banners" element={<AdminBanners type="edit" per="alb" path="banners" />}/>
                        <Route path="meusprocessos" element={<AdminMyProcessList type="list" />}/>
                        <Route path="meusprocessos/:id/comentarios" element={<AdminMyProcessCommentList type="list" />}/>
                        <Route path="meusprocessos/:id/prestacaodecontas" element={<AdminMyProcessPrestacaoContas type="list" />}/>
                        <Route path="meusite" element={<AdminMeuPerfil />}/>
                        <Route path="minhaconta" element={<AdminMinhaConta />}/>
                        <Route path="minhaconta/alterarplano" element={<AdminAlterarPlano />}/>
                        <Route path="minhaconta/alterarplano/:plan/:type" element={<AdminAlterarPlanoCart />}/>
                        <Route path="treinamentos" element={<Outlet />}>
                            <Route path="" element={<AdminTreinamentoList type="list" per="trn" path="training" />} />
                            <Route path="trash" element={<AdminTreinamentoList type="trash" per="trn" path="training" />} />
                            <Route path="add" element={<AdminTreinamentoForm type="add" per="trn" path="training" />} />
                            <Route path=":id" element={<AdminTreinamentoForm type="edit" per="trn" path="training" />} />
                            <Route path=":id/embed" element={<AdminTreinamentoEmbed type="edit" per="trn" path="training" />} />
                        </Route>
                        <Route path="usuarios" element={<Outlet />}>
                            <Route path="" element={<AdminUsuarioList type="list" per="usr" path="credential" />} />
                            <Route path="trash" element={<AdminUsuarioList type="trash" per="usr" path="credential" />} />
                            <Route path="add" element={<AdminUsuarioForm type="add" per="usr" path="credential" />} />
                            <Route path=":id" element={<AdminUsuarioForm type="edit" per="usr" path="credential" />} />
                        </Route>
                        <Route path="escritorios" element={<Outlet />}>
                            <Route path="" element={<AdminEscritorioList type="list" per="ect" path="office" />} />
                            <Route path="trash" element={<AdminEscritorioList type="trash" per="ect" path="office" />} />
                            <Route path="add" element={<AdminEscritorioForm type="add" per="ect" path="office" />} />
                            <Route path=":id" element={<AdminEscritorioForm type="edit" per="ect" path="office" />} />
                            <Route path=":id/plano" element={<AdminEscritorioPlano type="list" per="epl" path="office" />} />
                            <Route path=":id/tipousuario" element={<Outlet />}>
                                <Route path="" element={<AdminEscritorioTipoUsuarioList type="list" per="etu" path="office" />} />
                                <Route path="trash" element={<AdminEscritorioTipoUsuarioList type="trash" per="etu" path="office" />} />
                                <Route path="add" element={<AdminEscritorioTipoUsuarioForm type="add" per="etu" path="office" />} />
                                <Route path=":id" element={<AdminEscritorioTipoUsuarioForm type="edit" per="etu" path="office" />} />
                            </Route>
                            <Route path=":id/equipe" element={<Outlet />}>
                                <Route path="" element={<AdminEscritorioEquipeList type="list" per="eeq" path="office" />} />
                                <Route path="trash" element={<AdminEscritorioEquipeList type="trash" per="eeq" path="office" />} />
                                <Route path="add" element={<AdminEscritorioEquipeForm type="add" per="eeq" path="office" />} />
                                <Route path=":id" element={<AdminEscritorioEquipeForm type="edit" per="eeq" path="office" />} />
                            </Route>
                        </Route>
                        <Route path="planos" element={<Outlet />}>
                            <Route path="" element={<AdminPlanoList type="list" per="pln" path="plan" />} />
                            <Route path="trash" element={<AdminPlanoList type="trash" per="pln" path="plan" />} />
                            <Route path="add" element={<AdminPlanoForm type="add" per="pln" path="plan" />} />
                            <Route path="config" element={<Outlet />}>
                                <Route path="" element={<AdminPlanoConfigList type="list" per="plc" path="plan" />} />
                                <Route path="trash" element={<AdminPlanoConfigList type="trash" per="plc" path="plan" />} />
                                <Route path="add" element={<AdminPlanoConfigForm type="add" per="plc" path="plan" />} />
                                <Route path=":id" element={<AdminPlanoConfigForm type="edit" per="plc" path="plan" />} />
                            </Route>
                            <Route path=":id" element={<AdminPlanoForm type="edit" per="pln" path="plan" />} />
                            <Route path=":id/config" element={<AdminPlanoConfig type="edit" per="pln" path="plan" />} />
                        </Route>
                        <Route path="especialidades" element={<Outlet />}>
                            <Route path="" element={<AdminEspecialidadeList type="list" per="esp" path="specialty" />} />
                            <Route path="trash" element={<AdminEspecialidadeList type="trash" per="esp" path="specialty" />} />
                            <Route path="add" element={<AdminEspecialidadeForm type="add" per="esp" path="specialty" />} />
                            <Route path=":id" element={<AdminEspecialidadeForm type="edit" per="esp" path="specialty" />} />
                        </Route>
                        <Route path="perguntas" element={<Outlet />}>
                            <Route path="" element={<AdminPerguntaList type="list" per="prg"/>} />
                            <Route path="trash" element={<AdminPerguntaList type="trash" per="prg"/>} />
                            <Route path="add" element={<AdminPerguntaForm type="add" per="prg"/>} />
                            <Route path=":id" element={<AdminPerguntaForm type="edit" per="prg"/>} />
                        </Route>
                        <Route path="politicastermos" element={<Outlet />}>
                            <Route path="" element={<AdminPageList type="list" per="pet" path="" />} />
                            <Route path="trash" element={<AdminPageList type="trash" per="pet" path="" />} />
                            <Route path="aceites" element={<AdminPageAceites type="list" />} />
                            <Route path="add" element={<AdminPageForm type="add" per="pet" path="" />} />
                            <Route path=":id" element={<AdminPageForm type="edit" per="pet" path="" />} />
                            <Route path=":id/page" element={<AdminPagePage />} />
                            <Route path=":id/view/:ver" element={<AdminPageView />} />
                        </Route>
                        <Route path="metodospagamento" element={<Outlet />}>
                            <Route path="" element={<AdminMetodoPagamentoList type="list" per="mdp" />} />
                            <Route path="trash" element={<AdminMetodoPagamentoList type="trash" per="mdp" />} />
                            <Route path="add" element={<AdminMetodoPagamentoForm type="add" per="mdp" />} />
                            <Route path=":id" element={<AdminMetodoPagamentoForm type="edit" per="mdp" />} />
                        </Route>
                        <Route path="tribunais" element={<Outlet />}>
                            <Route path="" element={<AdminProcessoTribunalList type="list" per="ptb" />} />
                            <Route path="trash" element={<AdminProcessoTribunalList type="trash" per="ptb" />} />
                            <Route path="add" element={<AdminProcessoTribunalForm type="add" per="ptb" />} />
                            <Route path=":id" element={<AdminProcessoTribunalForm type="edit" per="ptb" />} />
                        </Route>
                        <Route path="instancias" element={<Outlet />}>
                            <Route path="" element={<AdminProcessoInstanciaList type="list" per="pis" />} />
                            <Route path="trash" element={<AdminProcessoInstanciaList type="trash" per="pis" />} />
                            <Route path="add" element={<AdminProcessoInstanciaForm type="add" per="pis" />} />
                            <Route path=":id" element={<AdminProcessoInstanciaForm type="edit" per="pis" />} />
                        </Route>
                        <Route path="situacoes" element={<Outlet />}>
                            <Route path="" element={<AdminProcessoSituacaoList type="list" per="pst" />} />
                            <Route path="trash" element={<AdminProcessoSituacaoList type="trash" per="pst" />} />
                            <Route path="add" element={<AdminProcessoSituacaoForm type="add" per="pst" />} />
                            <Route path=":id" element={<AdminProcessoSituacaoForm type="edit" per="pst" />} />
                        </Route>
                        <Route path="status" element={<Outlet />}>
                            <Route path="" element={<AdminProcessoStatusList type="list" per="pss" />} />
                            <Route path="trash" element={<AdminProcessoStatusList type="trash" per="pss" />} />
                            <Route path="add" element={<AdminProcessoStatusForm type="add" per="pss" />} />
                            <Route path=":id" element={<AdminProcessoStatusForm type="edit" per="pss" />} />
                        </Route>
                        <Route path="tipoacao" element={<Outlet />}>
                            <Route path="" element={<AdminProcessoTipoAcaoList type="list" per="pta" />} />
                            <Route path="trash" element={<AdminProcessoTipoAcaoList type="trash" per="pta" />} />
                            <Route path="add" element={<AdminProcessoTipoAcaoForm type="add" per="pta" />} />
                            <Route path=":id" element={<AdminProcessoTipoAcaoForm type="edit" per="pta" />} />
                        </Route>
                        <Route path="chanceganhar" element={<Outlet />}>
                            <Route path="" element={<AdminProcessoChanceGanharList type="list" per="pcg" />} />
                            <Route path="trash" element={<AdminProcessoChanceGanharList type="trash" per="pcg" />} />
                            <Route path="add" element={<AdminProcessoChanceGanharForm type="add" per="pcg" />} />
                            <Route path=":id" element={<AdminProcessoChanceGanharForm type="edit" per="pcg" />} />
                        </Route>
                        <Route path="tags" element={<Outlet />}>
                            <Route path="" element={<AdminTagList type="list" per="tag" />} />
                            <Route path="trash" element={<AdminTagList type="trash" per="tag" />} />
                            <Route path="add" element={<AdminTagForm type="add" per="tag" />} />
                            <Route path=":id" element={<AdminTagForm type="edit" per="tag" />} />
                        </Route>
                        <Route path="grupopermissao" element={<Outlet />}>
                            <Route path="" element={<AdminGrupoPermissaoList type="list" per="gdp" path="permission_group" />} />
                            <Route path="trash" element={<AdminGrupoPermissaoList type="trash" per="gdp" path="permission_group" />} />
                            <Route path="add" element={<AdminGrupoPermissaoForm type="add" per="gdp" path="permission_group" />} />
                            <Route path=":id" element={<AdminGrupoPermissaoForm type="edit" per="gdp" path="permission_group" />} />
                        </Route>
                        <Route path="permissao" element={<Outlet />}>
                            <Route path="" element={<AdminPermissaoList type="list" per="prm" path="permission" />} />
                            <Route path="trash" element={<AdminPermissaoList type="trash" per="prm" path="permission" />} />
                            <Route path="add" element={<AdminPermissaoForm type="add" per="prm" path="permission" />} />
                            <Route path=":id" element={<AdminPermissaoForm type="edit" per="prm" path="permission" />} />
                        </Route>
                        <Route path="tipousuario" element={<Outlet />}>
                            <Route path="" element={<AdminTipoDeUsuarioList type="list" per="tdu" path="credential_type" />} />
                            <Route path="trash" element={<AdminTipoDeUsuarioList type="trash" per="tdu" path="credential_type" />} />
                            <Route path="add" element={<AdminTipoDeUsuarioForm type="add" per="tdu" path="credential_type" />} />
                            <Route path=":id" element={<AdminTipoDeUsuarioForm type="edit" per="tdu" path="credential_type" />} />
                        </Route>
                        <Route path="tarefas" element={<Outlet />}>
                            <Route path="" element={<AdminTarefaList type="list" per="trf" />} />
                            <Route path="trash" element={<AdminTarefaList type="trash" per="trf" />} />
                            <Route path="add" element={<AdminTaskForm type="add" per="trf" />} />
                            <Route path="add/:id/process" element={<AdminTaskForm type="add" per="trf" />} />
                            <Route path="add/:id/client" element={<AdminTaskForm type="add" per="trf" />} />
                            <Route path=":id" element={<AdminTaskForm type="edit" per="trf" />} />
                            <Route path=":id/comentarios" element={<Outlet />}>
                                <Route path="" element={<AdminTaskCommentList type="list" per="tcm" />} />
                                <Route path="trash" element={<AdminTaskCommentList type="trash" per="tcm" />} />
                                <Route path="add" element={<AdminTaskCommentForm type="add" per="tcm" />} />
                                <Route path=":id" element={<AdminTaskCommentForm type="edit" per="tcm" />} />
                            </Route>
                            <Route path=":id/documentos" element={<Outlet />}>
                                <Route path="" element={<AdminTaskDocummentList type="list" per="tdc" />} />
                                <Route path="trash" element={<AdminTaskDocummentList type="trash" per="tdc" />} />
                                <Route path="add" element={<AdminTaskDocumentForm type="add" per="tdc" />} />
                                <Route path=":id" element={<AdminTaskDocumentForm type="edit" per="tdc" />} />
                            </Route>
                        </Route>
                        <Route path="financeiro" element={<Outlet />}>
                            <Route path="" element={<AdminProcessFinancialList per="fnc" type="list" />} />
                            <Route path=":id" element={<AdminProcessFinancialForm per="fnc" type="list" />} />
                        </Route>
                        <Route path="processos" element={<Outlet />}>
                            <Route path="" element={<AdminProcessList per="prc" type="list" />} />
                            <Route path="trash" element={<AdminProcessList per="prc" type="trash" />} />
                            <Route path="add" element={<AdminProcessForm per="prc" type="add" />} />
                            <Route path=":id" element={<AdminProcessForm per="prc" type="edit" />} />
                            <Route path=":id/prestacaodecontas" element={<AdminProcessPrestacaoContas type="edit" per="prc" />} />
                            <Route path=":id/comentarios" element={<Outlet />}>
                                <Route path="" element={<AdminProcessCommentList type="list" per="pcm" />} />
                                <Route path="trash" element={<AdminProcessCommentList type="trash" per="pcm" />} />
                                <Route path="add" element={<AdminProcessCommentForm type="add" per="pcm" />} />
                                <Route path=":id" element={<AdminProcessCommentForm type="edit" per="pcm" />} />
                            </Route>
                            <Route path=":id/documentos" element={<Outlet />}>
                                <Route path="" element={<AdminProcessDocumentList type="list" per="pdc" />} />
                                <Route path="trash" element={<AdminProcessDocumentList type="trash" per="pdc" />} />
                                <Route path="add" element={<AdminProcessDocumentForm type="add"  per="pdc"/>} />
                                <Route path=":id" element={<AdminProcessDocumentForm type="edit" per="pdc" />} />
                            </Route>
                        </Route>
                        <Route path="equipe" element={<Outlet />}>
                            <Route path="" element={<AdminEquipeList type="list" per="eqp" />} />
                            <Route path="trash" element={<AdminEquipeList type="trash" per="eqp" />} />
                            <Route path="add" element={<AdminEquipeForm type="add" per="eqp" />} />
                            <Route path=":id" element={<AdminEquipeForm type="edit" per="eqp" />} />
                        </Route>
                        <Route path="clientes" element={<Outlet />}>
                            <Route path="" element={<AdminClienteList type="list" per="cli" />} />
                            <Route path="trash" element={<AdminClienteList type="trash" per="cli" />} />
                            <Route path="add" element={<AdminClienteForm type="add" per="cli" />} />
                            <Route path=":id" element={<AdminClienteForm type="edit" per="cli" />} />
                            <Route path=":id/comentarios" element={<Outlet />}>
                                <Route path="" element={<AdminClientCommentList type="list" per="ccm" />} />
                                <Route path="trash" element={<AdminClientCommentList type="trash" per="ccm" />} />
                                <Route path="add" element={<AdminClientCommentForm type="add" per="ccm" />} />
                                <Route path=":id" element={<AdminClientCommentForm type="edit" per="ccm" />} />
                            </Route>
                            <Route path=":id/documentos" element={<Outlet />}>
                                <Route path="" element={<AdminClientDocumentList type="list" per="cdc" />} />
                                <Route path="trash" element={<AdminClientDocumentList type="trash" per="cdc" />} />
                                <Route path="add" element={<AdminClientDocumentForm type="add" per="cdc" />} />
                                <Route path=":id" element={<AdminClientDocumentForm type="edit" per="cdc" />} />
                            </Route>
                        </Route>
                        <Route path="partecontraria" element={<Outlet />}>
                            <Route path="" element={<AdminParteContrariaList type="list" per="ptc" />} />
                            <Route path="trash" element={<AdminParteContrariaList type="trash" per="ptc" />} />
                            <Route path="add" element={<AdminParteContrariaForm type="add" per="ptc" />} />
                            <Route path=":id" element={<AdminParteContrariaForm type="edit" per="ptc" />} />
                        </Route>
                        <Route path="advogados" element={<Outlet />}>
                            <Route path="" element={<AdminAdvogadoList type="list" per="adp" />} />
                            <Route path="trash" element={<AdminAdvogadoList type="trash" per="adp" />} />
                            <Route path="add" element={<AdminAdvogadoForm type="add" per="adp" />} />
                            <Route path=":id" element={<AdminAdvogadoForm type="edit" per="adp" />} />
                        </Route>
                        <Route path="*" element={<ControllerPage404 />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        )
        
    }

}

export default AppStack;